body {
    font-family: Poppins;
    /* overflow-y: hidden; */
  }
  
  .error{
    color: red;
    margin-top: -18px;
    margin-bottom: 14px;
    font-size: 14px;
  }

  .searchInput>::placeholder{/*CHrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /*Firefox */  
}

.addAchieverPopup .modal-content{
  background-color: #FFFFFF !important;
    border-radius: 10px !important;
    width: 348px !important;
    height: 323px !important;
    border: none;
}

.addAchieverPopup .modal-dialog
{
  display: flex;
    height: 75%;
    justify-content: center;
    align-items: center;
}

select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}


/*for removing default arrow of select option */

/* ::-webkit-scrollbar {
    height: 40px;
}

::-webkit-scrollbar:horizontal {
  width: 10px !important;
}

::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
} */