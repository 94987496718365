.navbar {
  display: flex;
  background-color: #0065f7;
  height: 60px;
  justify-content: space-between;
  align-items: center;
}

.navbar1 {
  display: flex;
  padding-left: 16px;
}

.menuIcon {
  color: white !important;
}

.linkOtherPage {
  color: #333333;
  text-decoration: none;
}

.linkOtherPage .iconPlacementInfo {
  background: url(../../../../assets/images/infoIcon.svg) no-repeat 4% 45%;
  padding-left: 35px;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  font-family: Poppins;
  font-style: bold !important;
  font-weight: 900;
  font-size: 14px;
  line-height: 35px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.linkOtherPage .iconPlacementNotes {
  background: url(../../../../assets/images/notesIcon.svg) no-repeat 4% 45%;
  padding-left: 35px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Poppins;
  font-style: bold !important;
  font-weight: 900;
  font-size: 14px;
  line-height: 35px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.linkOtherPage .iconPlacementTalks {
  background: url(../../../../assets/images/talksIcon.svg) no-repeat 4% 45%;
  padding-left: 35px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Poppins;
  font-style: bold !important;
  font-weight: 900;
  font-size: 14px;
  line-height: 35px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.linkOtherPage .iconPlacementGroup {
  background: url(../../../../assets/images/groupIcon.svg) no-repeat 4% 45%;
  padding-left: 35px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Poppins;
  font-style: bold !important;
  font-weight: 900;
  font-size: 14px;
  line-height: 35px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.linkOtherPage .iconPlacementReports {
  background: url(../../../../assets/images/reportsIcon.svg) no-repeat 4% 45%;
  padding-left: 35px;
  margin-left: 10px;
  margin-right: 10px;
  font-family: Poppins;
  font-style: bold !important;
  font-weight: 900;
  font-size: 14px;
  line-height: 35px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.linkOtherPage .iconPlacementSettings {
  background: url(../../../../assets/images/groupIcon.svg) no-repeat 4% 45%;
  padding-left: 35px;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: Poppins;
  font-style: bold !important;
  font-weight: 900;
  font-size: 14px;
  line-height: 35px;
  padding-bottom: 0px;
  padding-top: 0px;
}

.navsearch {
  padding-left: 30px;
  background-color: #0065f7;
  margin-left: 25px;
  margin-top: 8px;
  width: 120px;
  height: 32px;
  border: 0.5px;
  border-color: #cbcaca;
  border-style: solid;
  color: white;
  border-radius: 8px;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  text-align: left;
  line-height: 18px;
  outline: none;
  background: url(../../../../assets/images/SearchIcon.svg) no-repeat 5% 50%;
}
.navsearch:hover {
  border: 1px solid #c5bebe9e;
  border-radius: 8px;
}
.navsearch:active {
  background-color: #70a6f5;
}

.navbut {
  padding-right: 37px;
  padding-left: 8px;
  margin-left: 16px;
  margin-top: 8px;
  width: 120px;
  height: 32px;
  border: 0.5px;
  border-color: #cbcaca;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 11px;
  font-family: Poppins;
  line-height: 16px;
  font-weight: 500;
  text-align: left;
  background-color: #0065f7;
  background: url("../../../../assets/images/DownArrow.svg") no-repeat 90% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
}
.navbut:hover {
  border: 1px solid #c5bebe9e;
}
.navbut:active {
  background-color: #70a6f5;
}

.navbut1 {
  margin-right: 13px;
  padding-left: 0px;
  margin-left: 8px;
  width: 170px;
  height: 32px;
  border: none;
  color: white;
  font-size: 14px;
  font-family: Poppins;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  background-color: #0065f7;
  background: url("../../../../assets/images/DownArrow.svg") no-repeat 90% 50%;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
}
.navbut1:hover {
  border: 2px solid #c5bebe9e;
  border-radius: 8px;
}
.navbut1:active {
  background-color: #70a6f5;
}

option {
  background-color: white;
  font-family: Poppins;
  color: black;
}

.dropdown {
  width: auto;
  height: auto;
  position: absolute !important;
  right: 20px;
  top: 51px;
  background: white;
  border-radius: 7px;
  padding: 5px;
  box-shadow: 0px 3px 2px #aab2bd;
  box-sizing: border-box;
  z-index: 1;
}

.noDropdown {
  display: none;
}

.logoutButton {
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  color: black;
  background-color: white;
  padding-bottom: 10px;
  border: none;
  height: 35px;
  border-radius: 6px;
}
.logoutButton:hover {
  background-color: #f5f1f1;
  color: #2f80ed;
}
.logoutButton:active {
  border: 2px solid #dbd7d7;
}

.hrLine {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* .tabHead{
        background: #F2F2F2;
        border-radius: 8px;
        height: 36px;
        font-size: 12px;
      
      } */

/* .tabrow {
        height: 36px;
        font-size: 12px;
      } */

.overlay {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* background-color: rgba(128,128,128,0.5);  */
}

.noOverlay {
  display: none;
}

.feedback_date_picker_holder {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding-right: 37px;
  padding-left: 8px;
  margin-left: 16px;
  margin-top: 8px;
  width: 100%;
  height: 32px;
}

.feedback_datepicker{
  display: inline-flex;
  width: 120px;
  border: 0.5px;
  border-color: #cbcaca;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 11px;
  font-family: Poppins;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  background-color: #0065f7;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  height: 32px;
  margin-left: 10px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(1);
  margin: 0px;
  margin-right: 20px !important;
  width: 25px;
  cursor: pointer;
}

.feedback_date_picker_holder--label {
  width: auto;
  color: white;
  font-size: 11px;
  font-family: Poppins;
  line-height: 16px;
  font-weight: 500;
}

.get_feedback_btn {
  border: 0.5px;
  border-color: #cbcaca;
  border-style: solid;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 11px;
  font-family: Poppins;
  line-height: 16px;
  font-weight: 500;
  text-align: center;
  background-color: #0065f7;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  height: 32px;
  width: fit-content;
}