.head2 {
  display: flex;
  justify-content: space-between;
  margin-left: 30px;
  width: 97.5%;
  height: 60px;
  margin-top: -5px;
}

.h1AchieversInfo {
  width: 134px;
  height: 27px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 20px;
  color: #333333;
  margin-top: 18px;
}

.headButton {
  margin-top: 17px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 274px;
}

.statusButton {
  padding-right: 54px;
  background-color: white;
  border: 1px solid #dbd7d7;
  box-sizing: border-box;
  border-radius: 8px;
  width: 120px;
  height: 32px;
  background: url("../../../assets/images/DownArrowBlack.svg") no-repeat 90% 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.statusButton:hover {
  border: 2px solid #dbd7d7;
}
.statusButton:active {
  background-color: #d9d7d7;
}

.addAchieverButton {
  margin-left: 15px;
  background-color: white;
  color: #2f80ed;
  width: 150px;
  height: 30px;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.addAchieverButton:hover {
  border: 2px solid #2f80ed;
}
.addAchieverButton:active {
  background-color: #70a6f5;
}

.tableAch {
  width: 97.5%;
  margin-left: 15px;
}

.tableAch thead tr th {
  padding: 8px;
  text-align: center;
  color: #828282;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  height: 18px;
}
.tableAch thead {
  background-color: #f2f2f2;
  border-radius: 8px;
  height: 36px;
}
.tableAch thead tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableAch thead tr th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tableAch tbody tr td {
  text-align: center;
  height: 38px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-bottom: solid 1px #e0e0e0;
  padding-top: 3px;
}

.tableAch tbody tr {
  border-radius: 8px;
}

.checkbox {
  width: 14.33px;
  height: 14.33px;
  margin-top: 5px;
}

.editButton {
  border: none;
  background-color: white;
  padding: 6px;
}
.editButton:active {
  background-color: grey !important;
}
.editButton:hover {
  background-color: #cfcfcf;
  border-radius: 100%;
}

.rowText {
  color: #2f80ed;
}

.tableAch tbody tr .titleCaption {
  width: 286px;
  font-size: 10px;
  text-align: left;
}

.tableAch tbody tr .rowName {
  text-align: center;
  color: #2f80ed;
}

.tableAch th:nth-child(5),
.tableAch th:nth-child(6) {
  text-align: center;
  padding-left: 0px;
}

.paginationInfo {
  display: flex;
  justify-content: center;
  margin: 25px 0 10px 0;
}
.paginationInfo button:focus {
  background-color: #004adc !important;
}
.paginationInfo button:active {
  background-color: #004adc !important;
}

/* Add Aciever CSS */

.addAchieverForm {
  width: 348px !important ;
  height: 351px !important ;
  background: #ffffff;
  border-radius: 10px;
  padding: 0;
  margin-left: -16px;
  margin-top: -15px;
}

.lableAchiever {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 15px;
  color: #828282;
  margin-left: 18px;
}

.input {
  padding-left: 10px;
  margin-bottom: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-left: 18px;
  outline-color: #2f80ed;
  margin-right: 18px;
}

.selectOption {
  padding-left: 10px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  border: none;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  outline-color: #2f80ed;
  margin-left: 18px;
  background: url("../../../assets/images/DownArrowBlack.svg") no-repeat 97% 50%
    #f2f2f2;
}

.footerAddAchiever {
  display: flex;
  justify-content: space-between;
}

.btnCancel {
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  color: #2f80ed;
  width: 140px;
  height: 30px;
  margin-left: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.btnCancel:hover {
  border: 2px solid #2f80ed;
}
.btnCancel:active {
  background-color: #70a6f5;
}

.btnSave {
  background: #2f80ed;
  border-radius: 8px;
  background-color: #2f80ed;
  color: white;
  width: 140px;
  height: 30px;
  border: none;
  outline: none;
  margin-right: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.btnSave:hover {
  background-color: #1b529d;
}
.btnSave:active {
  background-color: #70a6f5 !important;
}

.developer {
  display: none;
}

.loadingTextData {
  align-items: center;
  color: black;
  font-size: 25px;
  margin-top: 25px;
  margin-left: 40%;
}

/* achiever name popup CSS starts */

.achieverNameOverlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}

.achieverNameDiv {
  overflow-y: scroll;
  height: auto;
  max-height: 200px;
  width: 310px;
  padding: 5px;
  border: 1px solid #aaa;
  background: white;
  position: absolute;
  border-radius: 10px;
  overflow-x: hidden;
  margin-right: 18px;
  margin-left: 18px;
}

.achieverNameDiv::-webkit-scrollbar {
  width: 9px;
}

.achieverNameClose {
  margin-left: 252px;
  /* border: 1px solid #aaa; */
  margin-right: 5px;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 700;
  font-size: 13px;
  height: 25px;
  /* margin: 0; */
  padding: 6px 10px 5px 13px;
  cursor: pointer;
}

.achieverNameClose:hover {
  background-color: #f2f2f2;
}

.achieverNameClose:active {
  background-color: #aaa;
}

.achieverNameLine {
  margin: 5px;
}

.achieverNameUl {
  /* border-bottom: 1px solid #aaa; */
  font-family: Poppins;
  font-style: normal;
  font-size: 12px;
  cursor: pointer;
  height: 30px;
  margin: 0;
  padding: 6px 10px 5px 13px;
  border-radius: 5px;
}

.achieverNameUl:hover {
  background-color: #f2f2f2;
  border-radius: 5px;
}

.achieverNameUl:active {
  background-color: #aaa;
  border-radius: 5px;
}

/* achiever name popup CSS ends */

/* Add Aciever CSS */
