/* search suggestion popup CSS starts */

.input {
  padding-left: 10px;
  margin-bottom: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-left: 18px;
  outline-color: #2f80ed;
  margin-right: 18px;
}

.achieverNameOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
  
  .achieverNameDiv {
    overflow-y: scroll;
    height: auto;
    max-height: 200px;
    width: 310px;
    padding: 5px;
    border: 1px solid #aaa;
    background: white;
    position: absolute;
    border-radius: 10px;
    overflow-x: hidden;
    margin-right: 18px;
    margin-left: 18px;
    list-style: none;
  }
  
  .achieverNameDiv::-webkit-scrollbar {
    width: 9px;
  }
  
  
  .achieverNameClose {
    margin-left: 252px;
    /* border: 1px solid #aaa; */
    margin-right: 5px;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: 700;
    font-size: 13px;
    height: 25px;
    /* margin: 0; */
    padding: 6px 10px 5px 13px;
    cursor: pointer;
    outline: none;
  }
  
  .achieverNameClose:hover {
    background-color: #f2f2f2;
  }
  .achieverNameClose:focus {
    background-color: #f2f2f2;
  }
  
  .achieverNameClose:active {
    background-color: #aaa;
  }
  
  .achieverNameLine {
    margin: 5px;
  }
  
  .achieverNameUl {
    /* border-bottom: 1px solid #aaa; */
    font-family: Poppins;
    font-style: normal;
    font-size: 12px;
    cursor: pointer;
    height: 30px;
    margin: 0;
    padding: 6px 10px 5px 13px;
    /* border-radius: 5px; */
    border: none;
    outline: none;
  }
  
  .achieverNameUl:hover {
    background-color: #f2f2f2;
    border-radius: 5px;
  }

  .achieverNameUl:focus {
    background-color: #f2f2f2;
    /* border-radius: 5px; */
    border: none;
  }
  
  .achieverNameUl:active {
    background-color: #aaa;
    border-radius: 5px;
  }
  
  /* search suggestion popup CSS ends */