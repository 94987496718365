.head2 {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  width: 97.5%;
  height: 60px;
  margin-top: -5px;
}

.h1AchieversNotes {
  width: 155px;
  height: 27px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 20px;
  color: #333333;
  margin-top: 18px;
}

.headButton {
  margin-top: 17px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 274px;
}

.statusButton {
  padding-right: 54px;
  background-color: white;
  border: 1px solid #dbd7d7;
  box-sizing: border-box;
  border-radius: 8px;
  width: 120px;
  height: 32px;
  background: url("../../../assets/images/DownArrowBlack.svg") no-repeat 90% 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.statusButton:hover {
  border: 2px solid #dbd7d7;
}
.statusButton:active {
  background-color: #d9d7d7;
}

.addAchieverTalksButton {
  margin-left: 15px;
  background-color: white;
  color: #2f80ed;
  width: 150px;
  height: 30px;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.addAchieverTalksButton:hover {
  border: 2px solid #2f80ed;
}
.addAchieverTalksButton:active {
  background-color: #70a6f5;
}

.tableAchTalks {
  width: 125%;
  margin-left: 0px;
}

.tableAchTalks thead tr th {
  padding: 0px;
  text-align: center;
  color: #828282;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #f2f2f2;
}
.tableAchTalks thead {
  background-color: #f2f2f2;
  border-radius: 8px;
  height: 36px;
}

.tableAchTalks thead tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableAchTalks thead tr th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tableContainer {
  overflow-x: scroll;
}

.tableAchTalks tr td:first-child,
.tableAchTalks th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  width: 90px;
}

.tableAchTalks tr td:nth-child(2),
.tableAchTalks th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 90px;
  z-index: 2;
  width: 80px;
}

.tableAchTalks tr td:nth-child(3),
.tableAchTalks th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 170px;
  z-index: 2;
  width: 110px;
}

.tableAchTalks tr td:nth-child(4),
.tableAchTalks th:nth-child(4) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 280px;
  z-index: 2;
  width: 110px;
}

.tableAchTalks tr td:nth-child(5),
.tableAchTalks th:nth-child(5) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 390px;
  z-index: 2;
  width: 120px;
  text-align: left;
  padding-left: 0px;
}

.tableAchTalks tr td:nth-child(6),
.tableAchTalks th:nth-child(6) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 510px;
  z-index: 2;
  width: 127px;
}

.tableAchTalks tr td:nth-child(7),
.tableAchTalks th:nth-child(7) {
  text-align: left;
  padding-left: 7px;
}

.tableAchTalks tbody tr td {
  text-align: center;
  height: 38px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-bottom: solid 1px #e0e0e0;
  padding-top: 3px;
  background-color: white;
}

::-webkit-scrollbar {
  position: absolute;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 5px;
}

.checkbox {
  width: 14.33px;
  height: 14.33px;
  margin-top: 5px;
}

.editButton {
  border: none;
  background-color: white;
  padding: 6px;
}
.editButton:active {
  background-color: grey !important;
}
.editButton:hover {
  background-color: #cfcfcf;
  border-radius: 100%;
}

.tableAchTalks tbody tr .titleCaption {
  width: 240px;
  font-size: 10px;
  color: #2f80ed;
  text-align: left;
  padding-left: 7px;
}

.rowNames {
  color: #2f80ed;
}

.date {
  width: 90px;
}
.views {
  width: 70px;
}

.paginationTalks {
  display: flex;
  justify-content: center;
  margin: 10px 0 0 0;
}

.paginationTalks button:focus {
  background-color: #004adc !important;
}
.paginationTalks button:active {
  background-color: #004adc !important;
}

/* Add Aciever CSS */

.addTalksForm {
  width: 348px !important ;
  height: auto !important ;
  background: #ffffff;
  border-radius: 10px;
  padding: 0;
  margin-left: -16px;
  margin-top: -15px;
}

.lableAchiever {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  margin-left: 18px;
}

.lableAchieverRed {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: red;
  margin-left: 18px;
}

.input {
  padding-left: 10px;
  margin-bottom: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-left: 18px;
  outline-color: #2f80ed;
  margin-right: 18px;
}

.selectOption {
  padding-left: 10px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px !important;
  width: 310px !important;
  height: 36px !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  outline-color: #2f80ed;
  margin-left: 18px;
  background: url("../../../assets/images/DownArrowBlack.svg") no-repeat 97% 50%
    #f2f2f2;
}

.selectOption1 {
  padding-left: 0px !important;
  padding-bottom: 4px !important;
  margin-bottom: 8px !important;
  border: none !important;
  /* box-sizing: border-box; */
  border-radius: 8px !important;
  width: 310px !important;
  height: 36px !important;
  font-family: Poppins !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 10px !important;
  outline-color: #2f80ed !important;
  margin-left: 18px !important;
  background-color: #f2f2f2 !important;
}

.css-1s2u09g-control {
  border-radius: 8px !important;
  background-color: #f2f2f2 !important;
  width: 310px !important;
  height: auto !important;
  background: url("../../../assets/images/DownArrowBlack.svg") no-repeat 97% 50%
    #f2f2f2;
}

.css-tlfecz-indicatorContainer .css-tj5bde-Svg {
  opacity: 0;
}

input .talksCheckbox {
  padding-top: 10px !important;
  display: flex !important;
  text-align: center !important;
  vertical-align: middle !important;
}

fieldset {
  border: 0 !important;
}
span {
  font-family: Poppins !important;
  font-style: normal;
  font-weight: normal;
  font-size: 10px !important;
}

.inputTitle {
  padding-left: 10px;
  margin-bottom: -7px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-left: 18px;
  outline-color: #2f80ed;
  margin-right: 18px;
}

.maxChr {
  padding-left: 252px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  color: #bdbdbd;
}

.insideText {
  position: absolute;
  left: 16px;
  width: 310px;
  opacity: 0;
}

.checkboxDropDown {
  width: 8px !important;
  height: 8px !important;
  transform: scale(0.7);
}
.dropdownText {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 34px !important;
  padding-left: 6px;
}

.talkThumbnail {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  margin-left: 132px;
  margin-bottom: 8px;
  margin-top: 10px;
  padding-top: 40px;
  padding-left: 0px;
  width: 80px;
  height: 120px;
  background: #c4c4c4;
  border-radius: 4px;
}

.talkThumbnailHiddenTextBefore {
  position: absolute;
  left: 132px;
  bottom: 105px;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
}

.talkThumbnailHiddenText {
  position: absolute;
  left: 132px;
  bottom: 104px;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
}

.talkThumbnailHiddenTextAfter {
  position: absolute;
  left: 132px;
  bottom: 191px;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
}

.talkThumbnailbutton {
  position: absolute;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  opacity: 0;
  cursor: pointer;
}

.talkThumbnailbutton:disabled {
  cursor: not-allowed;
}

.talkInputAttach {
  padding-left: 87px;
  padding-top: 9px;
  background-color: white;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-left: 18px;
  outline-color: #2f80ed;
  margin-right: 18px;
  color: #333333;
}

.talkPreviewImg {
  position: absolute;
  width: 80px;
  height: 120px;
  border-radius: 4px;
}
.talkThumbnailremoveButton {
  width: 66px;
  height: 18px;
  background: #c4c4c4;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #333333;
  position: absolute;
  bottom: 43%;
  left: 10%;
  padding-bottom: 17px;
  padding-top: 0px;
  border: none;
}

.footerAddAchiever {
  display: flex;
  justify-content: space-between;
}

.btnCancel {
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  color: #2f80ed;
  width: 140px;
  height: 30px;
  margin-left: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.btnCancel:hover {
  border: 2px solid #2f80ed;
}
.btnCancel:active {
  background-color: #70a6f5;
}

.btnSave {
  background: #2f80ed;
  border-radius: 8px;
  background-color: #2f80ed;
  color: white;
  width: 140px;
  height: 30px;
  border: none;
  outline: none;
  margin-right: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.btnSave:hover {
  background-color: #1b529d;
}
.btnSave:active {
  background-color: #70a6f5 !important;
}

.btnUpload {
  background: #2f80ed;
  border-radius: 8px;
  background-color: #2f80ed;
  color: white;
  width: 140px;
  height: 30px;
  border: none;
  outline: none;
  margin-right: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 1rem;
}
.btnUpload:hover {
  background-color: #1b529d;
}
.btnUpload:active {
  background-color: #70a6f5 !important;
}

.rmsc .dropdown-container {
  border-radius: 10px !important;
  height: 38px !important;
}

.talkDeleteIconBtn {
  background-color: white;
  border: none;
}

.talkDeleteIcon {
  font-size: 20px !important;
  /* color: #0065f7; */
  color: #2f80ed;
}

.talkDeleteIcon:hover {
  font-size: 20px !important;
  color: #0065f7;
}

.talkDeleteIcon:active {
  font-size: 20px !important;
  color: #ffffff;
  background-color: #0065f7;
  outline: none;
  border: none;
}

.popUpBack {
  position: fixed; /* Positioning and size */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(128, 128, 128, 0.5);
  z-index: 2;
}

.noPopUp {
  display: none;
}

.yesPopUp {
  position: fixed;
  top: 40%;
  left: 46%;
  width: 300px !important;
  margin-left: -100px;
  height: 150px;
  margin-top: -25px;
  background: white;
  /* border: solid 3px #d7d3d3; */
  z-index: 1;
  border-radius: 8px;
}

.popUpCloseBtn {
  position: relative;
  left: 270px;
  top: 1px;
  border: none;
  background-color: white;
  padding-right: 1px;
}

.closeIcon {
  font-size: 21px !important;
  color: gray;
}

.popUpCloseBtn:active {
  position: relative;
  left: 278px;
  top: 1px;
  border: none;
  background-color: rgb(189, 187, 187);
  font-weight: 700;
}

.warningDiv {
  display: flex;
  justify-content: center;
  margin-top: -17px;
}

.warningIcon {
  color: orange;
  font-size: 45px !important;
}

.popUph2 {
  display: flex;
  justify-content: center;
  margin-top: 0px;
  margin-bottom: 14px;
}

.popUpButtonDiv {
  display: flex;
  justify-content: center;
}

.popUpOkButton {
  color: #f6f8f7;
  background: #2f80ed;
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 5px 0;
}

.popUpOkButton:active {
  color: #f6f8f7;
  background: #0065f7;
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 5px 0;
}

.popUpCancelButton {
  color: white;
  background: grey;
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 0 5px;
}

.popUpCancelButton:active {
  color: white;
  background: rgb(182, 181, 181);
  text-transform: capitalize;
  padding: 6px 15px 6px 15px;
  justify-content: center;
  align-items: center;
  align-content: center;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  border: none;
  height: 46px;
  width: 50%;
  border-radius: 0 0 0 5px;
}


.loadingTextData{
  align-items: center;
  color: black;
  font-size: 25px;
}

/* Add Aciever CSS */
