.head2 {
  display: flex;
  justify-content: space-between;
  margin-left: 15px;
  width: 98.5%;
  height: 60px;
  margin-top: -5px;
}

.h1AchieversNotes {
  width: 155px;
  height: 27px;

  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  padding-bottom: 20px;
  color: #333333;
  margin-top: 18px;
}

.headButton {
  margin-top: 17px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  height: 30px;
  width: 274px;
}

.statusButton {
  padding-right: 54px;
  background-color: white;
  border: 1px solid #dbd7d7;
  box-sizing: border-box;
  border-radius: 8px;
  width: 120px;
  height: 32px;
  background: url("../../../assets/images/DownArrowBlack.svg") no-repeat 90% 50%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 11px;
  line-height: 16px;
}
.statusButton:hover {
  border: 2px solid #dbd7d7;
}
.statusButton:active {
  background-color: #d9d7d7;
}

.addAchieverNotesButton {
  margin-left: 15px;
  background-color: white;
  color: #2f80ed;
  width: 150px;
  height: 30px;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}
.addAchieverNotesButton:hover {
  border: 2px solid #2f80ed;
}
.addAchieverNotesButton:active {
  background-color: #70a6f5;
}

.tableAchNotes {
  width: 140%;
  margin-left: 0px;
}

.tableAchNotes thead tr th {
  padding: 0px;
  text-align: center;
  color: #828282;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  position: -webkit-sticky;
  position: sticky;
  background-color: #f2f2f2;
  padding-right: 10px;
}
.tableAchNotes thead {
  background-color: #f2f2f2;
  border-radius: 8px;
  height: 36px;
}

.tableAchNotes thead tr th:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableAchNotes thead tr th:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.tableContainer {
  overflow-x: auto;
}
/* tbody>tr>:nth-child(7),
tbody>tr>:nth-child(8), tbody>tr>:nth-child(9), tbody>tr>:nth-child(10), tbody>tr>:nth-child(11){ 
  overflow-x: scroll;
  } */

.tableAchNotes tr td:first-child,
.tableAchNotes th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  width: 90px;
}

.tableAchNotes tr td:nth-child(2),
.tableAchNotes th:nth-child(2) {
  position: -webkit-sticky;
  position: sticky;
  left: 79px;
  z-index: 2;
  width: 80px;
}

.tableAchNotes tr td:nth-child(3),
.tableAchNotes th:nth-child(3) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 159px;
  z-index: 2;
  width: 110px;
}

.tableAchNotes tr td:nth-child(4),
.tableAchNotes th:nth-child(4) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 266px;
  z-index: 2;
  width: 110px;
  padding-right: 0 !important;
}

.tableAchNotes tr td:nth-child(5),
.tableAchNotes th:nth-child(5) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 373px;
  z-index: 2;
  width: 120px;
  text-align: left;
  padding-left: 0px;
  padding-right: 0 !important;
}

.tableAchNotes tr td:nth-child(6),
.tableAchNotes th:nth-child(6) {
  position: -webkit-sticky;
  position: sticky !important;
  left: 490px;
  z-index: 2;
  width: 80px;
}
.tableAchNotes tr td:nth-child(7),
.tableAchNotes th:nth-child(7) {
  min-width: 100px;
}

.tableAchNotes tr td:nth-child(8),
.tableAchNotes th:nth-child(8) {
  text-align: left;
  padding-left: 7px;
}

.tableAchNotes tr td:nth-child(9),
.tableAchNotes th:nth-child(9) {
  min-width: 120px;
  overflow-wrap: break-word;
}

.tableAchNotes tr td:nth-child(10),
.tableAchNotes th:nth-child(10) {
  min-width: 120px;
  overflow-wrap: break-word;
}

.tableAchNotes tr td:nth-child(11),
.tableAchNotes th:nth-child(11) {
  min-width: 120px;
  overflow-wrap: break-word;
}

.tableAchNotes tr td:nth-child(12),
.tableAchNotes th:nth-child(12) {
  min-width: 120px;
  overflow-wrap: break-word;
}

.tableAchNotes tr td:nth-child(13),
.tableAchNotes th:nth-child(13) {
  min-width: 155px;
  overflow-wrap: break-word;
}

.tableAchNotes tr td:nth-child(14),
.tableAchNotes th:nth-child(14) {
  min-width: 60px;
  overflow-wrap: break-word;
}

.tableAchNotes tbody tr td {
  text-align: center;
  height: 38px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  border-bottom: solid 1px #e0e0e0;
  padding-top: 3px;
  padding-right: 10px;
  background-color: white;
  color: #333333;
}

::-webkit-scrollbar {
  position: absolute;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: gainsboro;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb {
  background: #bdbdbd;
  border-radius: 5px;
}

.checkbox {
  width: 14.33px;
  height: 14.33px;
  margin-top: 5px;
}

.editButton {
  border: none;
  background-color: white;
  padding: 6px;
}
.editButton:active {
  background-color: grey !important;
}
.editButton:hover {
  background-color: #cfcfcf;
  border-radius: 100%;
}

.tableAchNotes tbody tr .titleCaption {
  width: 240px;
  font-size: 10px;
  color: #2f80ed;
}

.tableAchNotes tbody tr .rowNames {
  color: #2f80ed;
}

.date {
  width: 90px;
}
.views {
  width: 70px;
}

.viewTime {
  width: 72px;
}

.paginationNotes {
  display: flex;
  justify-content: center;
  margin: 12px 0 0 0;
}

.paginationNotes button:focus {
  background-color: #004adc !important;
}
.paginationNotes button:active {
  background-color: #004adc !important;
}

/* Add Aciever CSS */

.addAchieverForm {
  width: 348px !important ;
  height: auto !important ;
  background: #ffffff;
  border-radius: 10px;
  padding: 0;
  margin-left: -16px;
  margin-top: -15px;
}

.lableAchiever {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #828282;
  margin-left: 18px;
}

.input {
  padding-left: 10px;
  margin-bottom: 8px;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  margin-left: 18px;
  outline-color: #2f80ed;
  margin-right: 18px;
}

.input:disabled {
  cursor: not-allowed;
}

.selectOption {
  padding-left: 10px;
  padding-bottom: 4px;
  margin-bottom: 8px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  outline-color: #2f80ed;
  margin-left: 18px;
  background: url("../../../assets/images/DownArrowBlack.svg") no-repeat 97% 50%
    #f2f2f2;
}

.inputAttach {
  padding-left: 103px;
  padding-top: 9px;
  background-color: white;
  border: 1px solid #bdbdbd;
  box-sizing: border-box;
  border-radius: 8px;
  width: 310px;
  height: 36px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  margin-left: 18px;
  outline-color: #2f80ed;
  margin-right: 18px;
  color: #333333;
}
.insideText {
  position: absolute;
  left: 16px;
  width: 310px;
  opacity: 0;
  cursor: pointer;
}
.insideText:disabled {
  cursor: not-allowed;
}

.fileName {
  text-align: center;
  margin-top: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 300px;
  overflow: hidden;
  display: inherit;
  margin-left: 20px;
}

.thumbnailDiv {
  position: relative;
}

.thumbnail {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  margin-left: 132px;
  margin-bottom: 12px;
  margin-top: 16px;
  padding-top: 40px;
  padding-left: 0px;
  width: 80px;
  height: 120px;
  background: #c4c4c4;
  border-radius: 4px;
  cursor: pointer;
}

.thumbnailDisabled {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  margin-left: 132px;
  margin-bottom: 12px;
  margin-top: 16px;
  padding-top: 40px;
  padding-left: 0px;
  width: 80px;
  height: 120px;
  background: #f1f1f1;
  border-radius: 4px;
  cursor: not-allowed;
}

.thumbnailHiddenTextBefore {
  position: absolute;
  left: 132px;
  bottom: 107px;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
}

.thumbnailHiddenText {
  position: absolute;
  left: 132px;
  bottom: 12px;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
}

.thumbnailHiddenTextLater {
  position: absolute;
  left: 132px;
  bottom: 48px;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
}
.notesThumbnailbutton {
  position: absolute;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  opacity: 0;
  cursor: pointer;
  /* bottom: 342px; */
}

.notesThumbnailbutton:disabled {
  cursor: not-allowed;
}

.previewImg {
  position: absolute;
  width: 80px;
  height: 120px;
  border-radius: 4px;
}
.thumbnailremoveButton {
  width: 66px;
  height: 18px;
  background: #c4c4c4;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #333333;
  position: absolute;
  bottom: 43%;
  left: 10%;
  padding-bottom: 17px;
  padding-top: 0px;
  border: none;
  cursor: pointer;
}

.thumbnailremoveButton:disabled {
  cursor: not-allowed;
}

.footerAddAchiever {
  padding-bottom: 17px;
  display: flex;
  justify-content: space-between;
}

.btnCancel {
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  background-color: white;
  color: #2f80ed;
  width: 140px;
  height: 30px;
  margin-left: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.btnCancel:hover {
  border: 2px solid #2f80ed;
}
.btnCancel:active {
  background-color: #70a6f5;
}

.btnSave {
  background: #2f80ed;
  border-radius: 8px;
  background-color: #2f80ed;
  color: white;
  width: 140px;
  height: 30px;
  border: none;
  outline: none;
  margin-right: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
}
.btnSave:hover {
  background-color: #1b529d;
}
.btnSave:active {
  background-color: #70a6f5 !important;
}

.btnSave:disabled {
  background-color: #c8dbf8 !important;
}

.notesUploadDiv {
  /* display: flex;
  justify-content: center;
  margin-bottom: -11px; */
  margin-left: 29.5%;
}

.notesUploadP {
  font-size: 14px;
  margin-bottom: 4px;
  margin-left: 21px;
}

.uploadLoadSpin {
  height: 30px !important;
  width: 22px !important;
  margin-right: 8px;
  margin-left: -12px;
}

.startUploadLoadSpin {
  height: 100px !important;
  width: 180px !important;
  /* margin-right: 8px; */
  /* margin-left: -12px; */
  position: absolute;
  left: 43%;
  top: 36%;
  z-index: 10000;
}

.noteLoadingDiv {
  display: flex;
  justify-content: center;
}

.allStatusDropdown {
  width: auto;
  height: auto;
  position: absolute !important;
  right: 159px;
  top: 115px;
  background: white;
  border-radius: 7px;
  padding: 5px;
  box-shadow: 0px 3px 2px #aab2bd;
  box-sizing: border-box;
  z-index: 1;
}

.logoutButtonBlue {
  width: 100%;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 35px;
  color: black;
  background-color: #1b529d;
  padding-bottom: 10px;
  border: none;
  height: 35px;
  border-radius: 6px;
  color: white;
}

.loadingTextData {
  align-items: center;
  color: black;
  font-size: 25px;
}

.previewDiv {
  overflow-x: scroll;
  display: flex;
  margin: 20px;
}

.labelInputPreviewDiv {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
}

.thumbnailPreview {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  margin-left: 30px;
  margin-bottom: 12px;
  margin-top: 16px;
  padding-top: 40px;
  padding-left: 0px;
  width: 80px;
  height: 120px;
  background: #c4c4c4;
  border-radius: 4px;
  cursor: pointer;
  grid-column-start: 1;
  grid-row-start: 1;
}

.thumbnailDisabledPreview {
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: #333333;
  margin-left: 30px;
  margin-bottom: 12px;
  margin-top: 16px;
  padding-top: 40px;
  padding-left: 0px;
  width: 80px;
  height: 120px;
  background: #f1f1f1;
  border-radius: 4px;
  cursor: not-allowed;
  grid-column-start: 1;
  grid-row-start: 1;
}

.notesThumbnailbuttonPreview {
  /*  position: absolute; */
  width: 80px;
  height: 120px;
  border-radius: 4px;
  opacity: 0;
  cursor: pointer;
  grid-row-start: 1;
  grid-column-start: 1;
  margin-left: 30px;
  margin-top: 16px;
  /* bottom: 342px; */
}

.notesThumbnailbuttonPreview:disabled {
  cursor: not-allowed;
}

.previewImgPreview {
  /* position: absolute; */
  width: 80px;
  height: 120px;
  border-radius: 4px;
  grid-row-start: 1;
  grid-column-start: 1;
  margin-left: 30px;
  margin-top: 16px;
}

/* .thumbnailHiddenTextPreview {
  position: absolute;
  left: 141px;
  bottom: 150px;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
  margin-left: 30px;
} */

/* .thumbnailHiddenTextLaterPreview {
  position: absolute;
  left: 132px;
  bottom: 106px;
  grid-row-start: 1;
  grid-column-start: 1;
  width: 80px;
  height: 120px;
  border-radius: 4px;
  margin-top: 16px;
  margin-left: 30px;
} */

.thumbnailremoveButtonPreview {
  width: 66px;
  height: 18px;
  background: #c4c4c4;
  border-radius: 4px;
  font-family: Poppins;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 18px;
  align-items: center;
  text-align: center;
  color: #333333;
  padding-bottom: 17px;
  padding-top: 0px;
  border: none;
  cursor: pointer;
  grid-row-start: 1;
  grid-column-start: 1;
  margin-left: 37px;
  margin-top: 72px;
  z-index: 5;
}

.thumbnailremoveButtonPreview:disabled{
  cursor: not-allowed;
}

.fileNamePreview {
  text-align: center;
  margin-top: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 80px;
  overflow: hidden;
  display: inherit;
  margin-left: 20px;
}

.btnUploadNotes {
  background: #2f80ed;
  border-radius: 8px;
  background-color: #2f80ed;
  color: white;
  width: 140px;
  height: 30px;
  border: none;
  outline: none;
  margin-right: 18px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 4px;
}
.btnUploadNotes:hover {
  background-color: #1b529d;
}
.btnUploadNotes:active {
  background-color: #70a6f5 !important;
}

.btnUploadNotes:disabled {
  cursor: not-allowed;
}

/* Add Aciever CSS */
