body {
  font-family: Poppins;
}
.logo {
  display: block;
  text-align: center;
  padding-top: 23px;
  padding-bottom: 8px;
}

.logoBtn {
  background-color: white;
  border: none;
}

.line {
  height: 1px;
  border: 1px solid #0065f7;
}

.h1login {
  width: 100px;
  height: 30px;
  background-color: #0065f7;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-top: 5px;

  border-radius: 4px;

  color: #f7f9f8;
}
.loginContent {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.loginTitle {
  display: flex;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.formDiv {
  margin: 0 auto;
}

.password,
.username {
  width: 100%;
  border-radius: 8px;
  color: var(--main-black);
  display: flex;
  padding: 10px;
  height: 50px;
  width: 340px;
  justify-content: space-between;
  align-items: center;
  border: 1px solid rgba(166, 200, 166, 0.25);
  box-sizing: border-box;
  box-shadow: inset 0px 1px 3px rgb(195 195 195 / 50%);
  margin-bottom: 18px;
}
.password_field,
.username_field {
  border: none;
  width: 100%;
  padding: 5px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 22px;
  opacity: 0.7;
  outline: none;
}

.forgotPassword {
  font-size: 12px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 12px;
  text-decoration: none;
}

.btn {
  width: 340px;
  height: 50px;
  border-radius: 8px;
  background-color: #0065f7 !important;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  background: url("../../../assets/images/LoginIcon.png") no-repeat 90% 50%;
  border: none;
  outline: none;
}

.btnText {
  width: 54px;
  height: 23px;
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 15px;
  line-height: 22px;
  color: #f6f8f7;
  margin-left: 16px;
}

.notMatching {
  font-size: 13px;
  color: red;
  display: block;
  text-align: left;
  margin-left: 14px;
  margin-top: -12px;
  margin-bottom: 10px;
}

.h1Reset {
  width: 150px;
  height: 30px;
  background-color: #0065f7;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  padding-top: 5px;

  border-radius: 4px;

  color: #f7f9f8;
}
