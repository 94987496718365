.delete-account-page {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: auto;
    gap: 12px;
    background: #F0F0F0;
}

.delete-account-page_header {
    display: flex;
    width: 100%;
    height: 40px;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    background: #F5F5F5;
}

.delete-account-page__stepper-container {
    display: flex;
    width: 100%;
    height: 500px;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
}

.stepper-container__header {
    display: flex;
    height: 30px;
    width: calc(100% - 760px);
    justify-content: space-around;
    align-items: center;
}

.stepper-container__main {
    display: flex;
    justify-content: flex-start;
    align-items:center;
    flex-direction: column;
    gap: 20px;
    width: calc(100% - 700px);
    height: 432px;
    background: #FFFFFF;
    box-shadow: 0px 4px 5px 0px #0000001A;
    border-radius: 16px;
    overflow: hidden;
}

.stepper-container__main-heading {
    width: 100%;
    height: 54px;
    display: flex;
    /* justify-content: center; */
    align-items: center;
    background: #E6E6E6;
    /* font-family: Inter; */
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: 0em;
    text-align: center;
    color: #373737;
}

.stepper-container__main-msg {
    /* font-family: Inter; */
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
    /* color: #373737; */
    max-width: 394px;
}

.stepper-container__main-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 6px;
    width: 45%;
    margin-top: 20px;
}

.main-form__email-input-field {
    width: 100%;
    padding: 8px 16px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    background: #F9FAFB;
    outline: none;
}

.main-form__email-input-field::placeholder{
    opacity: 0.5;
}

.main-form__otp-input-field {
    width: 12%;
    padding: 8px 0px;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    background: #F9FAFB;
    outline: none;
  text-align: center;
  /* Firefox */
  -moz-appearance: textfield;
}

/* Chrome, Safari, Edge, Opera */
.main-form__otp-input-field::-webkit-outer-spin-button,
.main-form__otp-input-field::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.main-form__submit-btn {
    padding: 10px 15px;
    width: fit-content;
    outline: none;
    border: none;
    border-radius: 8px;
    align-self: flex-end;
    background: #1A56DB;
    color: #fff;
    margin-top: 20px;
}

.main-form__label {
    font-size: 14px;
    font-weight: 500;
    line-height: 21px;
    letter-spacing: 0em;
    text-align: left;
}

.main-form__error-msg {
    color: rgb(119, 29, 29);
    font-weight: 500;
    text-align: center;
}

.main-form__submit-btn-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.main-form__cancel-btn {
     padding: 10px 15px;
    width: fit-content;
    color: "#1F2A37";
    border-radius: var(--rounded-lg, 8px);
    border: 1px solid var(--gray-200, #E5E7EB);
    margin-top: 15px;
    outline: 1px solid gray;
}

.stepper-container__main--success-msg {
    color: #009951;
text-align: center;
font-family: Inter;
font-size: 16px;
font-style: normal;
font-weight: 500;
line-height: 150%; /* 24px */
}