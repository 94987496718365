.reportsContainer {
  display: flex;
  justify-content: space-between;
}

.subContainer {
  width: 26%;
  border-right: 1px solid;
  border-color: #d8d8d8;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 24px;
}

.reportSerach {
  width: 230px;
  height: 30px;
  background-color: #f1f1f1;
  border-radius: 15px;
  border: none;
  margin-left: 9px;
  outline-color: #2f80ed;
  padding-left: 20px;
  padding-right: 10px;
}

.subContainer2 {
  width: 100%;
}

.exSubContainer2 {
  display: flex;
  justify-content: space-between;
  padding-right: 20px;
  padding-bottom: 16px;
}

.all {
  padding-left: 8px;
  margin-left: 7px;
  margin-top: 8px;
  width: 51px;
  height: 24px;
  border: none;
  box-sizing: border-box;
  border-radius: 8px;
  color: white;
  font-size: 12px;
  font-family: Poppins;
  font-style: normal;
  line-height: 18px;
  font-weight: 500;
  text-align: center;
  background-color: #0065f7;
  -webkit-appearance: none;
  -moz-appearance: none;
  outline: 0;
  margin-bottom: 30px;
}
.all:active {
  background-color: #70a6f5 !important;
}
.all:hover {
  background-color: #1b529d;
}

.bookmarks {
  margin-left: 10px;
  background-color: white;
  color: #004adc;
  width: 92px;
  height: 24px;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.bookmarks:active {
  background-color: #d9d7d7;
}
.bookmarks:hover {
  border: 2px solid #2f80ed;
}

.recent {
  margin-left: 10px;
  background-color: white;
  color: #004adc;
  width: 71px;
  height: 24px;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  margin-bottom: 30px;
  mix-blend-mode: normal;
  opacity: 0.8;
}
.recent:active {
  background-color: #d9d7d7;
}
.recent:hover {
  border: 2px solid #2f80ed;
}

.searchItem {
  font-family: Poppins;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 18px;
  color: #000000;
  width: 193px;
  height: 36px;
  margin-left: 30px;
}

.subContainer hr {
  border: 1px solid #e7e7e7;
  margin-left: 10px;
  margin-bottom: 6px;
}

.heading {
  font-family: Poppins;
  width: 346px;
  height: 27px;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 27px;
  color: #333333;
  width: auto;
  height: 27px;
  margin-left: 40px;
  margin-top: 30px;
  padding-left: 30px;
  background: url("../../../assets/images/ReportHeadingArrow.svg") no-repeat 1%
    50%;
}

.save {
  width: 20px;
  height: 20px;
  margin-top: 30px;
  background: url("../../../assets/images/ReportBookmark.svg") no-repeat 0% 50%;
  border: none;
}
.save:active {
  background-color: grey !important;
}
.save:hover {
  background-color: #cfcfcf;
  border-radius: 20%;
}
.xls {
  width: 20px;
  height: 20px;
  margin-top: 30px;
  margin-left: 10px;
  background: url("../../../assets/images/ReportXLS.svg") no-repeat 0% 50%;
  border: none;
}
.xls:active {
  background-color: grey !important;
}
.xls:hover {
  background-color: #cfcfcf;
  border-radius: 20%;
}

.pdf {
  width: 20px;
  height: 20px;
  margin-top: 30px;
  margin-left: 10px;
  background: url("../../../assets/images/ReportPDF.svg") no-repeat 0% 50%;
  border: none;
}
.pdf:active {
  background-color: grey !important;
}
.pdf:hover {
  background-color: #cfcfcf;
  border-radius: 20%;
}

.zoom {
  width: 30px;
  height: 20px;
  margin-top: 30px;
  margin-left: 10px;
  background: url("../../../assets/images/ReportZoom.svg") no-repeat 0% 50%;
  border: none;
}
.zoom:active {
  background-color: grey !important;
}
.zoom:hover {
  background-color: #cfcfcf;
  border-radius: 20%;
}

table {
  width: 95%;
  margin-left: 22px;
}

thead {
  background-color: #f2f2f2;
  width: auto;
  height: 36px;
  border-radius: 8px;
}

.tableContainerReports thead th {
  padding-left: 18px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #828282;
}

.tableContainerReports tbody td {
  width: auto;
  height: 40px;
  padding-left: 18px;
  text-align: center;
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  color: #2e2b2b;
}

.subContainer2 tbody tr:nth-child(even) {
  background: #f5f7fd;
  border: 1px;
  border-radius: 8px;
}

.tableContainerReports tbody tr td:first-child {
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.tableContainerReports tbody tr td:last-child {
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

table tbody tr td .imgStudent {
  width: 17px;
  height: 17px;
  margin-right: 10px;
}
