.mainDiv {
  background: #edeaea;
}

.h1Settings {
  width: 130px;
  height: 27px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 27px;
  padding-bottom: 20px;
  color: #333333;
  padding-top: 15px;
  margin-left: 35px;
}

.h2Settings {
  width: 74%;
  height: 40px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 0px;
  color: #333333;
  margin-top: 7px;
  /* background-color: #f1f1f1; */
  padding: 5px 5px 7px 20px;
  border-radius: 10px;
}

.containerDiv {
  /* display: flex;
  justify-content: space-between;
  padding: 10px 30px; */
  width: 70%;
  margin: 2% 15% 0 15%;
  padding-bottom: 1%;
}

.subContainerDiv {
  border: 1px solid #dbd7d7;
  box-shadow: 3px 3px 3px 3px #aab2bd;
  padding: 10px;
  box-sizing: border-box;
  color: #797979;
  background: white;
  margin-bottom: 20px;
  border-radius: 10px;
}

.settingsInput {
  width: 320px;
  border-radius: 10px;
  /* border: 1px solid gray; */
  border: none;
  margin-top: 20px;
  font-family: Poppins;
  font-style: normal;
  padding: 6px 10px;
  outline-color: #2f80ed;
  margin-left: 5%;
  background-color: #f1f1f1;
}

.boxContainer {
  display: block;
  /* width: 565px; */
  height: 100px;
  /* padding: 10px 0 10px 0; */
  margin: 20px 0;
  overflow-y: scroll;
  margin-left: 5%;
  margin-right: 5%;
}

.boxContainer::-webkit-scrollbar {
  width: 8px;
}

.boxDiv {
  display: inline;
  /* justify-content: flex-start; */
  /* height: 100px; */
  width: 600px;
}

.tagDiv {
  display: inline;
  height: 35px;
  margin: 10px;
  /* border: 1px solid gray; */
  background: #e7e1e1;
  border-radius: 10px;
  padding: 5px;
}

.tagP {
  display: inline;
  height: 30px;
  font-family: Poppins;
  font-style: normal;
}

.tagButton {
  height: 25px;
  font-size: 12px;
  border: none;
  background: #e7e1e1;
  margin-left: 5px;
  margin-top: 15px;
}

.settingsButton {
  margin-left: 15px;
  background-color: white;
  color: #2f80ed;
  width: 100px;
  height: 35px;
  border: 1px solid #2f80ed;
  box-sizing: border-box;
  border-radius: 8px;
  font-family: Poppins;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  text-align: center;
}

.settingsButton:hover {
  border: 2px solid #2f80ed;
}
.settingsButton:active {
  background-color: #70a6f5;
}
